import { useReducer } from "react";

import { getEditFormType, getFieldDefaults } from "../fieldConfigs";

export const initialState = {
  name: "",
  description: "",
  displayName: "",
  successMessage: "",
  errorMEssage: "",
  captcha: false,
  recipients: [],
  fields: [],
};

const generateId = () => Math.random().toString(36).slice(2, 9);

const setFormDetails = (
  state,
  {
    name,
    description,
    recipients,
    captcha,
    successMessage,
    errorMessage,
    displayName,
  }
) => {
  return {
    ...state,
    name,
    description,
    recipients,
    captcha,
    successMessage,
    errorMessage,
    displayName,
  };
};

const updateFormField = (state, { id, field }) => {
  return {
    ...state,
    fields: state.fields.map((formField) => {
      if (formField.id === id) {
        return field;
      }

      return formField;
    }),
  };
};

const deleteFormFieldOption = (state, { id }) => {
  return {
    ...state,
    fields: state.fields.map((field) => {
      if (field.options) {
        return {
          ...field,
          options: field.options.filter((option) => option.id !== id),
        };
      }

      return field;
    }),
  };
};

const deleteFormField = (state, { id }) => {
  return {
    ...state,
    fields: state.fields.filter((field) => field.id !== id),
  };
};

const addFormField = (state, { type, position }) => {
  const fieldDefaults = getFieldDefaults(type);

  const newField = {
    ...fieldDefaults,
    id: generateId(),
    editForm: getEditFormType(type),
    options:
      fieldDefaults.options?.map((option) => ({
        ...option,
        id: generateId(),
      })) || null,
    type,
  };

  // insert the new field at the correct position
  const updatedFields = [...state.fields];
  updatedFields.splice(position, 0, newField);

  return {
    ...state,
    fields: updatedFields,
  };
};

const addFormSpacer = (state, { position, height }) => {
  const newField = {
    id: generateId(),
    type: "spacer",
    height,
  };

  // check for existing spacer
  const existingSpacerIndex = state.fields.findIndex(
    (field) => field.type === "spacer"
  );

  // if there is an existing spacer, remove it and add a new one at the correct position
  if (existingSpacerIndex !== -1) {
    const updatedFields = [...state.fields];
    updatedFields.splice(existingSpacerIndex, 1);
    updatedFields.splice(position, 0, newField);

    return {
      ...state,
      fields: updatedFields,
    };
  }

  // insert the spacer at the correct position
  const updatedFields = [...state.fields];
  updatedFields.splice(position, 0, newField);

  return {
    ...state,
    fields: updatedFields,
  };
};

const addFormFieldOption = (state, { fieldId }) => {
  const field = state.fields.find(({ id }) => id === fieldId);

  const newOption = {
    id: generateId(),
    content: "",
    value: "",
    default: false,
  };

  return {
    ...state,
    fields: state.fields.map((field) => {
      if (field.id === fieldId) {
        return {
          ...field,
          options: [...field.options, newOption],
        };
      }

      return field;
    }),
  };
};

const editFormFieldOption = (state, { fieldId, optionId, name, value }) => {
  return {
    ...state,
    fields: state.fields.map((field) => {
      if (field.id === fieldId) {
        return {
          ...field,
          options: updateOptions(field.options, optionId, name, value),
        };
      }

      return field;
    }),
  };
};

const updateOptions = (options, optionId, name, value) => {
  return options.map((opt) => {
    if (name === "default" && opt.id !== optionId) {
      return { ...opt, default: false };
    }

    if (opt.id === optionId) {
      return { ...opt, [name]: value };
    }

    return opt;
  });
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "SET_FORM_DETAILS":
      return setFormDetails(state, action.payload);
    case "SET_FORM_FIELDS":
      return {
        ...state,
        fields: action.payload,
      };
    case "ADD_FORM_FIELD":
      if (action.payload.type) {
        return addFormField(state, action.payload);
      }
    case "ADD_FORM_FIELD_OPTION":
      return addFormFieldOption(state, action.payload);
    case "EDIT_FORM_FIELD_OPTION":
      return editFormFieldOption(state, action.payload);
    case "DELETE_FORM_FIELD_OPTION":
      return deleteFormFieldOption(state, action.payload);
    case "EDIT_FORM_FIELD":
      return updateFormField(state, action.payload);
    case "DELETE_FORM_FIELD":
      return deleteFormField(state, action.payload);
    case "ADD_FORM_SPACER":
      return addFormSpacer(state, action.payload);
    case "REMOVE_FORM_SPACER":
      return {
        ...state,
        fields: state.fields.filter((field) => field.type !== "spacer"),
      };
    case "SET_FORM":
      return action.payload;
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const useFormReducer = (initial = initialState) =>
  useReducer(FormReducer, initial);

export const useForm = (initial = initialState) => {
  const [form, dispatch] = useFormReducer(initial);

  const setFormDetails = (payload) =>
    dispatch({ type: "SET_FORM_DETAILS", payload });
  const setFormFields = (payload) =>
    dispatch({ type: "SET_FORM_FIELDS", payload });
  const addFormField = (payload) =>
    dispatch({ type: "ADD_FORM_FIELD", payload });
  const addFormFieldOption = (payload) =>
    dispatch({ type: "ADD_FORM_FIELD_OPTION", payload });
  const editFormFieldOption = (payload) =>
    dispatch({ type: "EDIT_FORM_FIELD_OPTION", payload });
  const deleteFormFieldOption = (payload) =>
    dispatch({ type: "DELETE_FORM_FIELD_OPTION", payload });
  const editFormField = (payload) =>
    dispatch({ type: "EDIT_FORM_FIELD", payload });
  const deleteFormField = (payload) =>
    dispatch({ type: "DELETE_FORM_FIELD", payload });
  const addFormSpacer = (payload) =>
    dispatch({ type: "ADD_FORM_SPACER", payload });
  const removeFormSpacer = (payload) =>
    dispatch({ type: "REMOVE_FORM_SPACER", payload });
  const setForm = (payload) => dispatch({ type: "SET_FORM", payload });
  const resetForm = () => dispatch({ type: "RESET" });

  return {
    form,
    setFormDetails,
    setFormFields,
    addFormField,
    addFormFieldOption,
    editFormFieldOption,
    deleteFormFieldOption,
    editFormField,
    deleteFormField,
    addFormSpacer,
    removeFormSpacer,
    setForm,
    resetForm,
  };
};

export default FormReducer;
