import { useState } from "react";
import { Box, Modal, Input, FormGroup } from "@bigcommerce/big-design";

const SettingsModal = ({ onClose, settings, saveSettings }) => {
    const [formData, setFormData] = useState(settings);

    const onSave = () => {
        saveSettings(formData)
        onClose();
    };

  return (
    <Modal isOpen={true} onClose={onClose} header="Edit Settings" actions={[
        {
            text: "Cancel",
            variant: "subtle",
            onClick: onClose,
        },
        {
          text: "Save",
          onClick: onSave,
        }
      ]}>
      <Box paddingBottom={"large"}>
        <FormGroup>
            <Input
                label="Sendgrid API Key"
                onChange={(e) => setFormData({ ...formData, 'sendgridApiKey': e.target.value })}
                type="text"
                value={formData.sendgridApiKey}
                required
            />
        </FormGroup>
        <FormGroup>
            <Input
                label="Sendgrid Verified Sender Email"
                onChange={(e) => setFormData({ ...formData, 'fromEmail': e.target.value })}
                type="text"
                value={formData.fromEmail}
                required
            />
        </FormGroup>
      </Box>
    </Modal>
  );
};

export default SettingsModal;
