import React from "react";

import { Flex, Button } from "@bigcommerce/big-design";

const FormActions = ({
  onCancel,
  cancelLabel = "Cancel",
  onSubmit,
  submitLabel = "Submit",
}) => {
  return (
    <Flex justifyContent="flex-end">
      <Button onClick={onCancel} variant="secondary">
        {cancelLabel}
      </Button>
      <Button onClick={onSubmit} type="submit">
        {submitLabel}
      </Button>
    </Flex>
  );
};

export default FormActions;
