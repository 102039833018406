import { InlineMessage, Link, Text, Flex, FlexItem, Button } from "@bigcommerce/big-design";
import { SettingsIcon, AddIcon } from '@bigcommerce/big-design-icons';
import React, { useEffect } from "react";
import {
  useNavigate,
  Routes,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";

import Home from "./Home";
import BuildPanel from "./BuildPanel";
import { alertsManager } from "../root.component";

export default function App({ props }) {
  const [savedForms, setSavedForms] = React.useState([]);
  const [settings, setSettings] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [hasSendgrid, setHasSendgrid] = React.useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = React.useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getSavedForms = async () => {
      await fetch(
        `${props.config.api_url}/api/forms/get-forms`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((responseJson) => {
          setSavedForms(responseJson[0]?.forms || []);
          setSettings(responseJson[0]?.settings || {});
          setLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
        });
    };

    getSavedForms();
  }, [
    props.config.api_url,
    props.config.store_hash,
    props.config.token,
    setSavedForms,
  ]);

  useEffect(() => {
    if (settings.sendgridApiKey !== "" && settings.fromEmail !== "") {
      setHasSendgrid(true);
    }
    else {
      setHasSendgrid(false);
    }
  }, [settings]);

  const navigateHome = () => {
    navigate("/form-builder");
  };

  const saveForm = async (form, deploy = false) => {
    if (!form.id) {
      const newId = Math.random().toString(36).slice(2, 9);
      const newForm = { ...form, id: newId };
      await postFormToServer([...savedForms, newForm], newId, deploy, false, form.name);
      setSavedForms([...savedForms, newForm]);
      return;
    }

    const updatedForms = savedForms.map((savedForm) =>
      savedForm.id === form.id ? form : savedForm
    );

    await postFormToServer(updatedForms, form.id, deploy, false, form.name);

    setSavedForms(updatedForms);
  };

  const deleteForm = async (formId) => {
    const getForm = savedForms.findIndex((form) => form.id === formId);

    const uuid = savedForms[getForm].uuid;
    const updatedForms = [...savedForms];

    updatedForms.splice(getForm, 1);

    await postFormToServer(updatedForms, uuid, false, true);

    setSavedForms(updatedForms);
  };

  const saveSettings = async (settings) => {
    try {
      const saveSettingsResponse = await fetch(
        `${props.config.api_url}/api/forms/save-settings`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            settings,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (saveSettingsResponse.ok) {
        const response = await saveSettingsResponse.json();
        alertsManager.add({ messages: [{ text: 'Settings saved' }], autoDismiss: true, type: 'success'}, () => null)
        setSettings(settings);
        return response;
      } else {
        throw new Error("Error saving settings");
      }
    } catch (error) {
      console.error("error", error);
      alertsManager.add({ messages: [{ text: 'Error saving settings' }], autoDismiss: true, type: 'error'}, () => null)
    }
  };

  const postFormToServer = async (
    forms,
    id,
    deploy = false,
    isDeleted = false,
    name
  ) => {
    try {
      const saveFormsResponse = await fetch(
        `${props.config.api_url}/api/forms/save-forms`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            formData: forms,
            isDeleted,
            deploy,
            id,
          }),
          headers: {
            "Content-Type": "application/json"
          },
        }
      );

      if (saveFormsResponse.ok) {
        const response = await saveFormsResponse.json();
        alertsManager.add({ messages: [{ text: `${name} has been ${isDeleted ? 'deleted' : 'saved'}` }], autoDismiss: true, type: 'success'}, () => null)
        return response;
      } else {
        throw new Error("Error saving forms");
      }
    } catch (error) {
      alertsManager.add({ messages: [{ text: `Error ${isDeleted ? 'deleting' : 'saving'} form ${name}` }], autoDismiss: true, type: 'error'}, () => null)
      console.error("error", error);
    }
  };

  const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => {
    return (
      <Modal
        variant="dialog"
        isOpen={isOpen}
        onClose={onClose}
        header="Cancel"
        actions={[
          {
            text: "Yes",
            onClick: () => {
              return true
            },
          },
          {
            text: "No",
            variant: "subtle",
            onClick: () => {
              onClose();
              return false
            },
          },
        ]}
      >
        <Text>
          {message}
        </Text>
      </Modal>
    );
  };

  return (
    <div>
      {!hasSendgrid && (
        <InlineMessage
          type="warning"
          header="FORMS REQUIRE TWILIO SENDGRID"
          messages={[{ text: "Please add your Sendgrid API Key and From Email in the settings" }]}
          marginBottom={'large'}
        />
      )}
      <Routes>
        <Route
          path="/form-builder"
          element={<Home savedForms={savedForms} deleteForm={deleteForm} saveSettings={saveSettings} loading={loading} settings={settings} />}
        />

        <Route path="form-builder/build/">
          <Route
            path=":formId"
            element={
              <BuildPanel
                forms={savedForms}
                onSaveForm={saveForm}
                navigateHome={navigateHome}
                hasSendgrid={hasSendgrid}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
}