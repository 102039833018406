import React from "react";

import {
  Form,
  FormGroup,
  Grid,
  GridItem,
  Textarea,
} from "@bigcommerce/big-design";

import FormActions from "../FormActions";

const EditTextBlock = ({ item, onChange, onSubmit, onCancel }) => {

  const onFieldSubmit = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <Form onSubmit={onFieldSubmit}>
      <Grid gridColumns={"1fr 1fr"}>
        <GridItem gridColumnStart={1} gridColumnEnd={3}>
          <FormGroup>
            <Textarea
              label="Content"
              name="content"
              value={item.content}
              onChange={onChange}
              required
              fullWidth
            />
          </FormGroup>
        </GridItem>
        <GridItem gridColumnStart={1} gridColumnEnd={3}>
          <FormActions onCancel={onCancel} />
        </GridItem>
      </Grid>
    </Form>
  );
};

export default EditTextBlock;
