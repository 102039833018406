import React, { useState } from "react";
import {
  H1,
  Text,
  Flex,
  FlexItem,
  FormGroup,
  Input,
  Button,
  Box,
  Textarea,
  ProgressCircle,
  Table,
  H3
} from "@bigcommerce/big-design";
import { AddIcon, SettingsIcon, EditIcon, DeleteIcon } from "@bigcommerce/big-design-icons";
import { useNavigate } from "react-router-dom";

import Panel from "./ui/Panel";
import SettingsModal from "./SettingsModal";

const Home = ({ savedForms, deleteForm, saveSettings, loading, settings }) => {
  const [isOpened, setIsOpened] = useState(false);
  const navigate = useNavigate();
  const handleNewForm = () => {
    navigate(`/form-builder/build/new`);
  };

  const handleEditForm = (form) => {
    navigate(`/form-builder/build/${form.id}`);
  };

  const handleDeleteForm = (form) => {
    deleteForm(form.id);
  };

  const handleSettings = () => {
    setIsOpened(true);
  };

  return (
    <Box>
      <Flex justifyContent={'space-between'} marginBottom={'medium'}>
        <FlexItem>
          <H1 marginBottom={'xSmall'}>Form Builder</H1>
          <Text>Browse or create new forms</Text>
        </FlexItem>
        <FlexItem>
          <Button variant="secondary" iconLeft={<AddIcon title="New Form" />} onClick={handleNewForm}>
            Create Form
          </Button>
          <Button variant="subtle" iconOnly={<SettingsIcon title="settings" />} onClick={handleSettings} />
        </FlexItem>
      </Flex>
      <Box shadow="raised" backgroundColor="white">
        <Box padding="medium">{savedForms.length} Form{savedForms.length !== 1 ? 's' : ''} found</Box>
        <MyFormsTable
            savedForms={savedForms}
            handleEditForm={handleEditForm}
            handleDeleteForm={handleDeleteForm}
            loading={loading}
        />
      </Box>
      {isOpened && <SettingsModal onClose={() => setIsOpened(false)} saveSettings={saveSettings} settings={settings} />}
    </Box>
  );
};

const MyFormsTable = ({ savedForms, handleEditForm, handleDeleteForm, loading }) => {
  return (
    <Box marginBottom="large" id="data-table">
      <Table
        columns={[
          { header: "Name", hash: "name", render: (item) => item.name },
          {
            header: "Actions",
            hash: "actions",
            render: (item) => (
              <Flex>
                <Button iconLeft={<EditIcon title={`Edit ${item.name}`} />} variant="subtle" onClick={() => handleEditForm(item)}>
                  Edit
                </Button>
                <Button
                  variant="subtle"
                  onClick={() => handleDeleteForm(item)}
                  marginLeft="small"
                  iconLeft={<DeleteIcon title={`Delete ${item.name}`} />}
                >
                  Delete
                </Button>
              </Flex>
            )
          }
        ]}
        items={savedForms}
        rowKey="id"
        emptyComponent={EmptyState(loading)}
      />
    </Box>
  );
};

const EmptyState = (isLoading) => {
  return (
    <Flex justifyContent="center" alignItems="center" padding="xLarge">
      {!isLoading ? 
        <Box>
          <H3>No forms found</H3>
        </Box> 
      : 
        <ProgressCircle size="medium" />}
    </Flex>
  );
};

export default Home;
