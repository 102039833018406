const { useRef, useEffect } = require("react");

const useStateHasChanged = (state) => {
  const hasChanged = useRef(false);
  const originalState = useRef(state);
  useEffect(() => {
    // compare current state to original state
    if (!hasChanged.current) {
      hasChanged.current =
        JSON.stringify(originalState.current) !== JSON.stringify(state);
    }
  }, [state]);
  return hasChanged.current;
};

export default useStateHasChanged;
