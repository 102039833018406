import React from "react";
import styled from "styled-components";

import {
  Input,
  Button,
  Checkbox,
  Flex,
  Box,
  H2,
} from "@bigcommerce/big-design";
import { DeleteIcon } from "@bigcommerce/big-design-icons";

const OptionsTableHeader = ({ fieldType }) => {
  return (
    <thead>
      <tr>
        <th>Label</th>
        {fieldType !== "checkbox" && <th>Value</th>}
        {fieldType !== "checkbox" && <THCentered>Default</THCentered>}
        {fieldType === "checkbox" && <THCentered>Checked</THCentered>}
      </tr>
    </thead>
  );
};

const THCentered = styled.th`
  text-align: center;
`;

const OptionsTableBody = ({
  options,
  onChange,
  fieldType,
  onAdd,
  onDelete,
}) => {
  return (
    <tbody>
      {options.map((option) => (
        <tr key={option.id}>
          <td>
            <Input
              value={option.content}
              onChange={(event) =>
                onChange({
                  id: option.id,
                  value: event.target.value,
                  name: "content",
                })
              }
            />
          </td>
          {fieldType !== "checkbox" && (
            <td>
              <Input
                value={option.value}
                onChange={(event) =>
                  onChange({
                    id: option.id,
                    value: event.target.value,
                    name: "value",
                  })
                }
              />
            </td>
          )}
          {fieldType === "checkbox" && (
            <td>
              <Flex justifyContent={"center"}>
                <Checkbox
                  checked={option.value}
                  onChange={(event) =>
                    onChange({
                      id: option.id,
                      value: event.target.checked,
                      name: "value",
                    })
                  }
                />
              </Flex>
            </td>
          )}
          {fieldType !== "checkbox" && (
            <td>
              <Flex justifyContent={"center"}>
                <Checkbox
                  checked={option.default}
                  onChange={(event) =>
                    onChange({
                      id: option.id,
                      value: event.target.checked,
                      name: "default",
                    })
                  }
                />
              </Flex>
            </td>
          )}
          <td>
            <Flex justifyContent={"flex-end"}>
              <Button
                type="button"
                iconOnly={<DeleteIcon />}
                onClick={() => onDelete(option.id)}
              />
            </Flex>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const OptionsTable = ({ options, fieldType, onChange, onDelete, onAdd }) => {
  return (
    <Box>
      <Flex justifyContent={"space-between"}>
        <H2>Options</H2>
        <Button type="button" onClick={onAdd}>
          Add Option
        </Button>
      </Flex>
      <StyledTable>
        <OptionsTableHeader fieldType={fieldType} />
        <OptionsTableBody
          options={options}
          fieldType={fieldType}
          onChange={onChange}
          onDelete={onDelete}
        />
      </StyledTable>
    </Box>
  );
};

const StyledTable = styled.table`
  width: 100%;
`;

export default OptionsTable;
