export const typeOptions = [
  {
    value: "checkbox-single",
    content: "Checkbox",
    editForm: "plain",
    defaults: {
      label: "Checkbox",
      placeholder: "Enter Text",
      required: true,
      fullWidth: true,
      value: false,
    },
  },
  {
    value: "date",
    content: "Date",
    editForm: "plain",
    defaults: { label: "Date", required: true, fullWidth: true },
  },
  {
    value: "email",
    content: "Email",
    editForm: "plain",
    defaults: { label: "Email", required: true, fullWidth: true },
  },
  // {
  //   value: "heading",
  //   content: "Heading",
  //   editForm: "plain"
  // },
  {
    value: "number",
    content: "Number",
    editForm: "plain",
    defaults: {
      label: "Number",
      required: true,
      fullWidth: true,
      min: null,
      max: null,
    },
  },
  {
    value: "radio",
    content: "Radio Group",
    editForm: "multiselect",
    defaults: {
      label: "Radio Group",
      options: [
        { value: "option1", content: "Option 1" },
        { value: "option2", content: "Option 2" },
      ],
      required: true,
      fullWidth: true,
    },
  },
  {
    value: "select",
    content: "Select",
    editForm: "multiselect",
    defaults: {
      label: "Select",
      options: [
        { value: "option1", content: "Option 1" },
        { value: "option2", content: "Option 2" },
      ],
      required: true,
      fullWidth: true,
    },
  },
  {
    value: "tel",
    content: "Telephone",
    editForm: "plain",
    defaults: { label: "Telephone", required: true, fullWidth: true },
  },
  {
    value: "text",
    content: "Text",
    editForm: "plain",
    defaults: {
      label: "Text Input",
      placeholder: "Enter Text",
      required: true,
      fullWidth: true,
    },
  },
  {
    value: "textarea",
    content: "Text Area",
    editForm: "plain",
    defaults: {
      label: "Textarea",
      placeholder: "Enter Text",
      required: true,
      fullWidth: true,
    },
  },
  {
    value: "textblock",
    content: "Text Block",
    editForm: "textblock",
    defaults: { content: "Enter Text", fullWidth: true },
  }
];

export const getFieldConfig = (type) =>
  typeOptions.find((item) => item.value === type);

export const getFieldDefaults = (type) => {
  const option = typeOptions.find((item) => item.value === type);
  return option.defaults;
};

export const getEditFormType = (type) => {
  const option = typeOptions.find((item) => item.value === type);
  return option.editForm;
};
