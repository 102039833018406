import { Box } from "@bigcommerce/big-design";
import styled from "styled-components";

const Panel = styled(Box)`
  background: #fff;
  box-shadow: 0px 1px 6px rgba(49, 52, 64, 0.2);
  border: none;
  border-radius: 4px;
  padding: 24px;
`;

export default Panel;
