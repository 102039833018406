import React from "react";

import {
  Form,
  FormGroup,
  Grid,
  GridItem,
  Input,
  Checkbox,
} from "@bigcommerce/big-design";

import FormActions from "../FormActions";
import OptionsTable from "./EditOptionsTable";

/**
 * @param {Object} props
 * @param {Object} props.item
 * @param {function} props.onChange
 * @param {function} props.onOptionChange
 * @param {function} props.onOptionDelete
 * @param {function} props.onOptionAdd
 * @param {function} props.onSubmit
 * @param {function} props.onCancel
 * @returns {JSX.Element}
 */
const EditMultiselectField = ({
  item,
  onChange,
  onOptionChange,
  onOptionDelete,
  onOptionAdd,
  onSubmit,
  onCancel,
}) => {
  return (
    <Form onSubmit={onSubmit}>
      <Grid gridColumns={"1fr 1fr"}>
        <GridItem>
          <FormGroup>
            <Input
              label="Label"
              name="label"
              value={item.label}
              onChange={onChange}
            />
          </FormGroup>
        </GridItem>
        <GridItem>
          {item.type === "select" && (
            <FormGroup>
              <Input
                label="Placeholder"
                name="placeholder"
                value={item.placeholder}
                onChange={onChange}
              />
            </FormGroup>
          )}
        </GridItem>

        {item.type !== "checkbox" && (
          <GridItem>
            <FormGroup>
              <Checkbox
                label="Required"
                name="required"
                checked={item.required}
                onChange={onChange}
              />
            </FormGroup>
          </GridItem>
        )}
        <GridItem>
          <Checkbox
            label="Full Width"
            name="fullWidth"
            checked={item.fullWidth}
            onChange={onChange}
          />
        </GridItem>
        <GridItem gridColumnStart={1} gridColumnEnd={3}>
          <OptionsTable
            options={item.options}
            fieldType={item.type}
            onChange={onOptionChange}
            onDelete={onOptionDelete}
            onAdd={onOptionAdd}
          />
        </GridItem>
        <GridItem marginTop={"large"} gridColumnStart={1} gridColumnEnd={3}>
          <FormActions onCancel={onCancel} />
        </GridItem>
      </Grid>
    </Form>
  );
};

export default EditMultiselectField;
