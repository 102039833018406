import React, { useReducer, useMemo } from "react";

import EditPlainField from "./EditPlainField";
import EditMultiselectField from "./EditMultiselectField";
import EditTextBlock from "./EditTextBlock";
import FormReducer from "../../../reducers/FormReducer";

/**
 * @param {object} props
 * @param {string} props.fieldId
 * @param {object} props.form
 * @param {function} props.onSubmit
 * @param {function} props.onCancel
 * @returns {JSX.Element}
 */
const EditField = ({ fieldId, form, onSubmit, onCancel }) => {
  const [draftForm, dispatch] = useReducer(FormReducer, form);

  const draftField = useMemo(
    () => draftForm.fields.find((field) => field?.id === fieldId),
    [draftForm, fieldId]
  );

  const editFormType = draftField.editForm;

  const onOptionAdd = () => {
    dispatch({
      type: "ADD_FORM_FIELD_OPTION",
      payload: { fieldId: draftField.id },
    });
  };

  const onOptionDelete = (id) => {
    dispatch({ type: "DELETE_FORM_FIELD_OPTION", payload: { id } });
  };

  const onSubmitDraft = (e) => {
    e.preventDefault();

    const newFormItems = draftForm.fields.map((formField) => {
      if (formField.id === draftField.id) {
        return draftField;
      }

      return formField;
    });

    onSubmit(newFormItems);
  };

  const onChange = (e) => {
    const { type } = e.target;

    const newValue =
      type === "checkbox" || type === "radio"
        ? e.target.checked
        : e.target.value;

    dispatch({
      type: "EDIT_FORM_FIELD",
      payload: {
        id: draftField.id,
        field: {
          ...draftField,
          [e.target.name]: newValue,
        },
      },
    });
  };

  const onOptionChange = ({ id, value, name }) => {
    dispatch({
      type: "EDIT_FORM_FIELD_OPTION",
      payload: {
        fieldId: draftField.id,
        optionId: id,
        name,
        value,
      },
    });
  };

  switch (editFormType) {
    case "plain":
      return (
        <EditPlainField
          item={draftField}
          onChange={onChange}
          onSubmit={onSubmitDraft}
          onCancel={onCancel}
        />
      );
    case "multiselect":
      return (
        <EditMultiselectField
          item={draftField}
          onChange={onChange}
          onSubmit={onSubmitDraft}
          onCancel={onCancel}
          onOptionChange={onOptionChange}
          onOptionDelete={onOptionDelete}
          onOptionAdd={onOptionAdd}
        />
      );
    case 'textblock':
      return (
        <EditTextBlock
          item={draftField}
          onChange={onChange}
          onSubmit={onSubmitDraft}
          onCancel={onCancel}
        />
      );
    default:
      return null;
  }
};

export default EditField;
