import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  DndContext,
  pointerWithin,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import CustomDndPointerSensor from "../CustomDndSensor";

import { Panel, Button, Grid, Modal, Box, Text, H1, Flex, FlexItem, Link } from "@bigcommerce/big-design";
import { AddIcon, SettingsIcon } from "@bigcommerce/big-design-icons";

import ToolBar from "./ToolBar";
import FormRenderer from "./FormRenderer";
import EditFieldModal from "./EditFieldModal";
import DetailsForm from "./Forms/DetailsForm";

import useStateHasChanged from "../hooks/useStateHasChanged";
import { useFormReducer } from "../reducers/FormReducer";

const getDragData = (event) => {
  const { active, over, collisions } = event;

  const data = {
    collisions,
  };

  if (active?.data?.current) {
    const {
      id: activeId,
      data: { current: activeData },
    } = active;

    data.active = {
      id: activeId,
      data: activeData,
      rect: {
        height: active.rect.height,
        width: active.rect.width,
      },
    };
  }

  if (over?.data?.current) {
    const {
      id: overId,
      data: { current: overData },
    } = over;

    data.over = {
      id: overId,
      data: overData,
      rect: {
        height: over.rect.height,
        width: over.rect.width,
      },
    };
  }

  return data;
};

export default function BuildPanel({ forms = [], onSaveForm, navigateHome, hasSendgrid }) {
  const params = useParams();
  const formId = params.formId;
  const [localForm, dispatch] = useFormReducer();
  const { fields } = localForm;
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [editingField, setEditingField] = useState(null);

  // track if state has changed since mount to determine if we should warn user on cancel
  const stateHasChanged = useStateHasChanged(localForm);

  useEffect(() => {
    if (formId && formId !== "new") {
      const form = forms.find((form) => form.id === formId);

      dispatch({ type: "SET_FORM", payload: form });
    }
  }, [formId, forms]);

  const handleAddFormField = (type, position) => {
    dispatch({ type: "ADD_FORM_FIELD", payload: { type, position } });
  };

  const handleRemoveFormField = (id) => {
    dispatch({ type: "DELETE_FORM_FIELD", payload: { id } });
  };

  const handleCancel = () => {
    if (stateHasChanged) {
      setCancelDialogOpen(true);
      return;
    }

    navigateHome();
  };

  const navigate = useNavigate();
  const handleBack = (e) => {
    e.preventDefault();
    navigate(`/form-builder`);
  };

  const handleEditFormField = (id) => {
    const field = fields.find((field) => field.id === id);
    setEditingField(field);
  };

  const setFormFields = (items) => {
    dispatch({ type: "SET_FORM_FIELDS", payload: items });
  };

  const handleSaveForm = () => {
    onSaveForm({ ...localForm });
    navigateHome();
  };

  const handleDeployForm = () => {
    onSaveForm({ ...localForm }, true);
    navigateHome();
  };

  const setFormDetails = (details) => {
    dispatch({ type: "SET_FORM_DETAILS", payload: details });
  };

  /**
   *
   * Drag and Drop
   *
   **/
  const [activeSidebarField, setActiveSidebarField] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const spacerActive = fields?.find((field) => field.type === "spacer");
  const sensors = useSensors(useSensor(CustomDndPointerSensor));

  const handleDragStart = (event) => {
    const { active } = getDragData(event);
    const { origin } = active.data;

    setIsDragging(true);

    if (origin === "toolbar") {
      return setActiveSidebarField(active);
    }

    setActiveSidebarField(null);
  };

  const handleDragOver = (event) => {
    const { active, over, collisions } = getDragData(event);

    if (
      !collisions.find((collision) => collision.id === "form") &&
      spacerActive
    ) {
      return dispatch({ type: "REMOVE_FORM_SPACER" });
    }

    if (over?.data?.sortable && !active.data?.sortable && !spacerActive) {
      const index = over.data.sortable.index;
      const height = over.rect.height;

      return dispatch({
        type: "ADD_FORM_SPACER",
        payload: { index, height },
      });
    }

    if (over?.data?.sortable && spacerActive) {
      const spacerIndex = localForm.fields.findIndex(
        (field) => field?.type === "spacer"
      );

      const overIndex = over.data.sortable.index;

      if (spacerIndex === overIndex) {
        return;
      }

      const sortArray = arrayMove(fields, spacerIndex, overIndex);

      setFormFields(sortArray);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = getDragData(event);

    setIsDragging(false);

    if (activeSidebarField && spacerActive) {
      const { type } = activeSidebarField?.data;
      const overIndex = spacerActive
        ? fields.findIndex((field) => field.type === "spacer")
        : null;

      handleAddFormField(type, overIndex);
    } else if (active && over) {
      const activeIndex = active.data?.sortable?.index;
      const overIndex = over.data?.sortable?.index;

      // this happens when there are no fields in the form, so just add a field normally
      if (!activeIndex && !overIndex) {
        return handleAddFormField(activeSidebarField?.data?.type, overIndex);
      }

      const sortArray = arrayMove(fields, activeIndex, overIndex ?? 0);

      setFormFields(sortArray);
    }

    if (spacerActive) {
      dispatch({ type: "REMOVE_FORM_SPACER" });
    }

    setActiveSidebarField(null);
  };

  const handleDragCancel = () => {
    setIsDragging(false);
    setActiveSidebarField(null);
    dispatch({ type: "REMOVE_FORM_SPACER" });
  };

  return (
    <StyledBuildPanel isDragging={isDragging}>
      <Flex justifyContent={'space-between'} marginBottom={'medium'}>
        <FlexItem>
          <H1 marginBottom={'xSmall'}>Add Form</H1>
          <Text> <Link href="#" onClick={handleBack}>Forms</Link> / Add Form</Text>
        </FlexItem>
      </Flex>
      <Panel header="Form Details">
        <DetailsForm form={localForm} setForm={setFormDetails} />
      </Panel>
      <Panel header="Form Fields">
        <DndContext
          sensors={sensors}
          collisionDetection={pointerWithin}
          onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          onDragStart={handleDragStart}
          onDragCancel={handleDragCancel}
        >
          <Grid gridColumns="1fr 300px">
            <Box>
              <FormRenderer
                formItems={fields}
                setFormItems={setFormFields}
                handleEditItem={handleEditFormField}
                disableColumns={isDragging}
                handleRemoveItem={handleRemoveFormField}
              />
            </Box>
            <ToolBar onAddItem={handleAddFormField} />
          </Grid>
          <DragOverlay dropAnimation={true}>
            {activeSidebarField ? (
              <StyledToolBarItem>
                {activeSidebarField.data.field.content}
              </StyledToolBarItem>
            ) : null}

            {/* {!activeSidebarField && activeField ? (
              <DisplayFormField
                id={activeField.id}
                type={activeField.data.type}
                noHover={true}
                {...activeField}
              />
            ) : null} */}
          </DragOverlay>
        </DndContext>
      </Panel>

      <StyledActions>
        <Button onClick={handleCancel} variant="subtle">
          Cancel
        </Button>
        <Button
          disabled={localForm.name ? false : true}
          onClick={handleSaveForm}
          variant="secondary"
        >
          Save
        </Button>
        <Button
          disabled={
            (!localForm.name && !localForm.recipients.length > 0) ||
            !stateHasChanged || !hasSendgrid
          }
          onClick={handleDeployForm}
        >
          Deploy
        </Button>
      </StyledActions>

      <ConfirmationDialog
        isOpen={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        onConfirm={navigateHome}
      />

      {!!editingField && (
        <EditFieldModal
          onClose={() => setEditingField(null)}
          form={localForm}
          fieldId={editingField.id}
          setFormItems={setFormFields}
          formItems={fields}
        />
      )}
    </StyledBuildPanel>
  );
}

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Cancel"
      actions={[
        {
          text: "Yes",
          onClick: onConfirm,
        },
        {
          text: "No",
          variant: "subtle",
          onClick: onClose,
        },
      ]}
    >
      <Text>
        Any unsaved changes will be lost. Are you sure you want to cancel?
      </Text>
    </Modal>
  );
};

const StyledBuildPanel = styled.div`
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "default")};
`;

const StyledToolBarItem = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  background-color: ${({ theme }) => theme.colors.secondary10};
  cursor: grabbing;
`;

const StyledActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.medium};
`;
