import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Text } from "@bigcommerce/big-design";
import styled from "styled-components";
import DisplayFormField from "./DisplayFormField";

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  height: 100%;

  .dnd-item {
    width: 100%;
    height: auto;
    cursor: grab;
  }

  .dnd-item:active {
    cursor: grabbing;
  }

  .dnd-item.half-width {
    width: calc(50% - ${({ theme }) => theme.spacing.small} / 2);
  }
`;

const SortableFormField = ({ id, fullWidth, ...rest }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, data: { ...rest } });
  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
        transition,
      }
    : undefined;

  const className = !fullWidth ? "dnd-item half-width" : "dnd-item";

  return (
    <div
      ref={setNodeRef}
      className={className}
      style={style}
      {...listeners}
      {...attributes}
    >
      <DisplayFormField id={id} {...rest} />
    </div>
  );
};

const StyledSpacer = styled.div`
  height: ${({ height }) => height}px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary20};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
`;

const FormSpacer = ({ height }) => {
  return <StyledSpacer height={height} />;
};

export default function FormRenderer({
  formItems,
  setFormItems,
  handleEditItem,
  disableColumns,
}) {
  function handleDeleteItem(id) {
    const newFormItems = formItems.filter((item) => item.id !== id);
    setFormItems(newFormItems);
  }

  const { setNodeRef } = useDroppable({
    id: "form",
    data: { type: "form" },
  });

  return (
    <SortableContext
      items={formItems}
      strategy={verticalListSortingStrategy}
      id="form"
    >
      <FormContainer ref={setNodeRef}>
        {formItems.length === 0 && (
          <Text>Add Elements to your form using the right menu</Text>
        )}

        {formItems.map((item) => {
          if (item.type === "spacer")
            return <FormSpacer key={item.id} {...item} />;

          return (
            <SortableFormField
              key={item.id}
              {...item}
              handleDeleteItem={() => handleDeleteItem(item.id)}
              handleEditItem={() => handleEditItem(item.id)}
              fullWidth={disableColumns ? true : item.fullWidth}
            />
          );
        })}
      </FormContainer>
    </SortableContext>
  );
}
