import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import { GlobalStyles, createAlertsManager, AlertsManager } from "@bigcommerce/big-design";
import { theme } from "@bigcommerce/big-design-theme";
import { ThemeProvider } from "styled-components";

import App from "./components/App";

import './forms.css'

export default function Root(props) {
  console.log('theme', theme)
  const newTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: "#C018A2",
      primary60: "#c018a2",
      primary50: "#9f0f85",
      primary20: "#f1ace5",
      primary10: "#faeff8",
    }
  };

  return (
    <ThemeProvider theme={newTheme}>
      <GlobalStyles />
      <AlertsManager manager={alertsManager} />
      <BrowserRouter>
        <App props={props} />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export const alertsManager = createAlertsManager();