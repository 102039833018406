import { PointerSensor } from "@dnd-kit/core";

class CustomDndPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown",
      handler: (event) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    "button",
    "input",
    "textarea",
    "select",
    "option",
  ];

  const noDndClass =
    element.classList.contains("no-dnd") || element.closest(".no-dnd");

  if (noDndClass) {
    return true;
  }

  const isInteractiveChild = interactiveElements.some((item) => {
    const hasElement = element.closest(item);
    return !!hasElement;
  });

  return isInteractiveChild;
}

export default CustomDndPointerSensor;
