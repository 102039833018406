import { Box, Modal } from "@bigcommerce/big-design";
import EditField from "./Forms/EditField/EditField";

/**
 *
 * @param {object} props
 * @param {function} props.onClose
 * @param {object} props.item
 * @param {function} props.setFormItems
 * @param {object[]} props.formItems
 * @returns {JSX.Element}
 */
const EditFieldModal = ({ onClose, item, setFormItems, fieldId, form }) => {
  const onSubmit = (formItems) => {
    setFormItems(formItems);
    onClose();
  };

  return (
    <Modal isOpen={true} onClose={onClose} header="Edit Form Field">
      <Box paddingBottom={"large"}>
        <EditField
          item={item}
          form={form}
          onSubmit={onSubmit}
          fieldId={fieldId}
          onCancel={onClose}
        />
      </Box>
    </Modal>
  );
};

export default EditFieldModal;
