import React from "react";

import {
  Grid,
  Input,
  Textarea,
  FormGroup,
  Checkbox,
} from "@bigcommerce/big-design";

const DetailsForm = ({ form, setForm }) => {
  const {
    name,
    recipients,
    response,
    displayName,
    successMessage,
    errorMessage,
  } = form;

  return (
    <Grid gridColumns="1fr 1fr">
      <FormGroup>
        <Input
          label="Form Name*"
          description={"The name of the form"}
          value={name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Input
          label="Display Name"
          description={"Heading shown at the top of the form"}
          value={displayName}
          onChange={(e) => setForm({ ...form, displayName: e.target.value })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Input
          label="Recipients"
          description={"Comma separated list of email addresses"}
          value={recipients}
          onChange={(e) => setForm({ ...form, recipients: e.target.value })}
          required
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          label="Captcha"
          description={"Enable captcha on this form"}
          checked={form.captcha}
          onChange={(e) => setForm({ ...form, captcha: e.target.checked })}
        />
      </FormGroup>
      {/* <FormGroup>
        <Textarea
          label="Form Description"
          value={description}
          onChange={(e) => setForm({ ...form, description: e.target.value })}
        />
      </FormGroup> */}
      <FormGroup>
        <Textarea
          label="Success Message"
          description={"Message shown after form is submitted successfully"}
          value={successMessage}
          onChange={(e) => setForm({ ...form, successMessage: e.target.value })}
        />
      </FormGroup>
      <FormGroup>
        <Textarea
          label="Error Message"
          description={
            "Message shown after form encounters an error sending the information to the server"
          }
          value={errorMessage}
          onChange={(e) => setForm({ ...form, errorMessage: e.target.value })}
        />
      </FormGroup>
    </Grid>
  );
};

export default DetailsForm;
