import React, { useState } from "react";

import {
  FormGroup,
  Input,
  Select,
  Textarea,
  Button,
  Checkbox,
  Datepicker,
  Radio,
  Box,
  H4,
} from "@bigcommerce/big-design";
import { EditIcon, DeleteIcon } from "@bigcommerce/big-design-icons";
import styled from "styled-components";

const FloatingButtons = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.small};
`;

const InputField = ({ label, name, placeholder, type }) => {
  return (
    <Input
      label={label}
      name={name}
      placeholder={placeholder}
      type={type}
      readOnly
      className="inertInput"
      required
      fullWidth
    />
  );
};

const TextAreaField = ({ label, name, placeholder }) => {
  return (
    <Textarea
      label={label}
      name={name}
      placeholder={placeholder}
      readOnly
      className="inertInput"
      required
    />
  );
};

const defaultOptions = [
  { value: "option1", content: "Option 1" },
  { value: "option2", content: "Option 2" },
];

const SelectField = ({
  label,
  name,
  placeholder,
  options = defaultOptions,
}) => {
  const value = options.filter((option) => option.default)?.[0]?.value;

  return (
    <FormGroup>
      {!!value ? (
        <Select
          placeholder={placeholder}
          label={label}
          name={name}
          options={options}
          required
          value={value}
        />
      ) : (
        <Select
          placeholder={placeholder}
          label={label}
          name={name}
          options={options}
          required
        />
      )}
    </FormGroup>
  );
};

const CheckboxField = ({ label, name, placeholder, value }) => {
  return (
    <Checkbox
      label={label}
      name={name}
      placeholder={placeholder}
      readOnly
      required
      checked={value}
    />
  );
};

const CheckBoxGroupField = ({ label, name, options = defaultOptions }) => {
  return (
    <H4 as="label" htmlFor={name}>
      {label}
      <FormGroup>
        {options.map((option, index) => (
          <Checkbox
            key={index}
            label={option.content}
            name={option.name}
            readOnly
            required
            checked={option.value}
          />
        ))}
      </FormGroup>
    </H4>
  );
};

const RadioGroupField = ({ label, name, options = defaultOptions }) => {
  return (
    <H4 as="label" htmlFor={name}>
      {label}
      <FormGroup>
        {options.map((option, index) => (
          <Radio
            key={index}
            label={option.content}
            name={option.name}
            readOnly
            required
            checked={option.default}
          />
        ))}
      </FormGroup>
    </H4>
  );
};

const DateField = ({ label, name, placeholder }) => {
  return (
    <Datepicker
      label={label}
      name={name}
      placeholder={placeholder}
      readOnly
      required
      onDateChange={() => {}}
    />
  );
};

const TextBlock = ({ content }) => {
  return (
    <div>{content}</div>
  )
};

export const DynamicField = ({ type, ...props }) => {
  switch (type) {
    case "text":
      return <InputField type={type} {...props} />;
    case "textarea":
      return <TextAreaField {...props} />;
    case "select":
      return <SelectField {...props} />;
    case "checkbox":
      return <CheckBoxGroupField {...props} />;
    case "checkbox-single":
      return <CheckboxField {...props} />;
    case "radio":
      return <RadioGroupField {...props} />;
    case "radio-single":
      return <Radio {...props} />;
    case "date":
      return <DateField {...props} />;
    case "number":
      return <InputField type={type} {...props} />;
    case "email":
      return <InputField type={type} {...props} />;
    case "tel":
      return <InputField type={type} {...props} />;
    case "spacer":
      return <div style={{ height: "20px" }} />;
    case "textblock":
      return <TextBlock {...props} />;
    default:
      return null;
  }
};

const DisplayFormField = ({
  type,
  handleDeleteItem,
  handleEditItem,
  noHover,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <FieldContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <DynamicField type={type} {...props} />
      <Box
        display={isHovered && !noHover ? "block" : "none"}
        position="relative"
        className="no-dnd"
      >
        <FloatingButtons>
          <Button
            onClick={handleEditItem}
            iconOnly={<EditIcon title="Edit" />}
          />
          <Button
            onClick={handleDeleteItem}
            iconOnly={<DeleteIcon title="Delete" />}
          />
        </FloatingButtons>
      </Box>
    </FieldContainer>
  );
};

const FieldContainer = styled.div`
  position: relative;
  cursor: grab;
  // margin: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  background-color: ${({ theme }) => theme.colors.secondary10};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  height: auto;

  :active {
    cursor: grabbing;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }

  input,
  textarea,
  select {
    cursor: pointer;
    pointer-events: none;
  }
`;

export default DisplayFormField;
