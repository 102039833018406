import React from "react";

import {
  Form,
  FormGroup,
  Grid,
  GridItem,
  Input,
  Checkbox,
} from "@bigcommerce/big-design";

import FormActions from "../FormActions";

const EditPlainField = ({ item, onChange, onSubmit, onCancel }) => {
  const [minError, setMinError] = React.useState("");
  const [maxError, setMaxError] = React.useState("");

  const onFieldSubmit = (e) => {
    e.preventDefault();

    if (item.min > item.max) {
      setMinError("Min must be less than max");
      setMaxError("Max must be greater than min");

      return e.stopPropagation();
    }

    onSubmit(e);
  };

  return (
    <Form onSubmit={onFieldSubmit}>
      <Grid gridColumns={"1fr 1fr"}>
        <GridItem>
          <FormGroup>
            <Input
              label="Label"
              name="label"
              value={item.label}
              onChange={onChange}
              required
            />
          </FormGroup>
        </GridItem>
        <GridItem>
          {item.type !== "checkbox-single" && (
            <FormGroup>
              <Input
                label="Placeholder"
                name="placeholder"
                value={item.placeholder}
                onChange={onChange}
              />
            </FormGroup>
          )}
        </GridItem>
        {item.type === "number" && (
          <>
            <GridItem>
              <FormGroup>
                <Input
                  label="Min"
                  name="min"
                  type="number"
                  error={minError}
                  value={item.min}
                  onChange={onChange}
                />
              </FormGroup>
            </GridItem>
            <GridItem>
              <FormGroup>
                <Input
                  label="Max"
                  name="max"
                  type="number"
                  error={maxError}
                  value={item.max}
                  onChange={onChange}
                />
              </FormGroup>
            </GridItem>
          </>
        )}
        <GridItem>
          <Checkbox
            label="Required"
            name="required"
            checked={item.required}
            onChange={onChange}
          />
        </GridItem>
        {item.type === "checkbox-single" && (
          <GridItem>
            <Checkbox
              label="Checked"
              name="value"
              checked={item.value}
              onChange={onChange}
            />
          </GridItem>
        )}
        <GridItem>
          <Checkbox
            label="Full Width"
            name="fullWidth"
            checked={item.fullWidth}
            onChange={onChange}
          />
        </GridItem>
        <GridItem gridColumnStart={1} gridColumnEnd={3}>
          <FormActions onCancel={onCancel} />
        </GridItem>
      </Grid>
    </Form>
  );
};

export default EditPlainField;
