import React, { useState } from "react";
import { useDraggable, useDroppable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

import styled from "styled-components";

import { typeOptions } from "../fieldConfigs";

const ToolBar = () => {
  const { setNodeRef } = useDroppable({
    id: "toolbar",
    data: { type: "toolbar" },
  });

  const toolOptions = typeOptions.map((item) => {
    return {
      ...item,
      id: `${item.value}-${Math.random().toString(36).substr(2, 9)}`,
    };
  });

  return (
    <ItemsContainer ref={setNodeRef}>
      {toolOptions.map((item, index) => (
        <ToolBarItem
          key={index}
          text={item.content}
          id={item.id}
          type={item.value}
          field={item}
        />
      ))}
    </ItemsContainer>
  );
};

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.medium};
`;

const ToolBarItem = ({ text, id, data, type, field }) => {
  const { setNodeRef, listeners, attributes, transform, transition } =
    useDraggable({
      id: id,
      data: {
        origin: "toolbar",
        field,
        type: type,
      },
    });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div {...listeners} {...attributes} style={style} ref={setNodeRef}>
      <StyledToolBarItem>{text}</StyledToolBarItem>
    </div>
  );
};

const StyledToolBarItem = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.colors.secondary20};
  border-radius: ${({ theme }) => theme.borderRadius.normal};
  cursor: grab;
`;

export default ToolBar;
